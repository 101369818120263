(function ($) {
  Drupal.behaviors.tabbedBlockV1 = {
    attached: false,
    attach: function (context) {
      if (this.attached) {
        return;
      }
      this.attached = true;

      var $template = $('.js-tabbed-block-v1', context);
      var $blockTabs = $('.tabbed-block__tab', $template);
      var $blockContents = $('.tabbed-block__content-item', $template);

      $blockTabs.once().on('click', function () {
        var $this = $(this);
        var index = $this.index();

        $blockTabs.attr('aria-expanded', 'false');
        $this.attr('aria-expanded', 'true');
        $blockContents.attr('aria-hidden', 'true');
        $blockContents.eq(index).attr('aria-hidden', 'false');
      });
    }
  };
})(jQuery);
